import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  private headerMenuSource = new BehaviorSubject<string[]>([]);
  headerMenu$ = this.headerMenuSource.asObservable();

 
  private currentSidebarSelectionSubject = new BehaviorSubject<string>(this.getInitialSidebarSelection());
  currentSidebarSelection$ = this.currentSidebarSelectionSubject.asObservable();

  // Get initial value for sidebar from localStorage
  private getInitialSidebarSelection(): string {
    return localStorage.getItem('activeSidebarItem') || 'Main Report';  // Default to 'Main Report' if nothing is stored
  }

  // Function to update sidebar selection
  changeSidebarSelection(selection: any) {
    this.currentSidebarSelectionSubject.next(selection);
    localStorage.setItem('activeSidebarItem', selection);
  }

}
