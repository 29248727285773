import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { SidebarService } from './../sidebar/sidebar.service';
import { HeadermenuService } from './../sidebar/headermenu.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionHandler } from 'src/app/auth/handlers/permission.handler';
import { Permissions } from 'src/app/auth/consts/permissions';
import { CurrentUserModel } from 'src/app/core/models/current-user';
import { UtilityService } from 'src/app/core/services/utility.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  currentMenuType: string;
  activeMenu: string = 'homepage';
  permissions = Permissions;
  routerLinkPermit: string;
  routerLinkViolations: string;
  routerLinkSettings: string;
  currentUser: CurrentUserModel;

  //Permit
  isAnyPermitsPermission: boolean;

  isAllPermit: boolean;
  isAllPermitSettingsPermission: boolean;

  //Violations 
  isAnyViolationsPermission: boolean;

  isAllViolationsPermission: boolean;
  isAllConfigurationPermission: boolean;

  //Settings
  isAnySettingsPermission: boolean;
  isAllLocationPermission: boolean;
  isAllUsersPermission: boolean;
  isAllVehiclePermission: boolean;
  isAllPaymentPermission: boolean;

  constructor(public sidebarservice: SidebarService, private cd: ChangeDetectorRef,private utils: UtilityService,
    public permissionHandler: PermissionHandler, public HeadermenuService: HeadermenuService, private translate: TranslateService,
  ) {
    this.activeMenu = localStorage.getItem('activeMenu')
    this.translate.onLangChange.subscribe(() => {
      this.onMenuItemSelect(this.currentMenuType);
    });
  }

  getSideBarSate() {
    return this.sidebarservice.getSidebarState();
  }
  ngOnInit() {
    this.checkAllPermissions();
    this.currentUser = this.utils.storage.CurrentUser;
  }
  menuItems: string[] = [];
  headerMenu: string[] = []; // assuming this is populated elsewhere

  // new code for sidebar selection
  selectSidebarItem(selectedHeader: string) {
    this.activeMenu = selectedHeader;
    this.currentMenuType = selectedHeader;
    localStorage.setItem('activeMenu', this.activeMenu)
    this.sidebarservice.changeSidebarSelection(selectedHeader);
    if (selectedHeader === "Permits") {
      this.checkPermissionsPermitRouter();
    } else if (selectedHeader === "Setting") {
      this.checkPermissionsSettingsRouter();
    }
  }
  // end new code

  onMenuItemSelect(menuType: any) {
    this.activeMenu = menuType;
    this.currentMenuType = menuType;

    let menuItems = [];
    switch (menuType) {
      case 'Home':
        menuItems = [];
        this.HeadermenuService.updateHeaderMenu(menuItems);
        break;

      case 'Analytics':
        this.translate.get('MENU.ANALYTICS').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'Permits':
        this.translate.get('MENU.PERMIT').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'Violations':
        this.translate.get('MENU.VIOLATION').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;

      case 'Notices':
        this.translate.get('MENU.NOTICE').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;
      case 'Setting':
        this.translate.get('MENU.SETTING').subscribe((res: string[]) => {
          menuItems = res;
          this.HeadermenuService.updateHeaderMenu(menuItems);
        });
        break;
      default:
        menuItems = [];
    }
  }
  checkAllPermissions() {
    this.checkPermissionsPermitRouter();
    //this.checkPermissionsViolationsRouter();
    this.checkPermissionsSettingsRouter();
  }

  checkPermissionsPermitRouter(): any {
    debugger
    this.cd.detectChanges();
    //Dashboard
    let permitDashboardPermissions = this.permissionHandler.checkPermission([this.permissions.dashboard_permit.View]);

    //Permit
    let permitBookingPermissions = this.permissionHandler.checkPermission([this.permissions.book_permit.View]);
    let facilitiesPermissions = this.permissionHandler.checkPermission([this.permissions.Facilities.View]);

    //Permit Settings
    let permitTypePermissions = this.permissionHandler.checkPermission([this.permissions.permitType.View]);
    let documentCategoryPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);
    let checklistsPermissions = this.permissionHandler.checkPermission([this.permissions.checklist.View]);
    let pricingPackagePermissions = this.permissionHandler.checkPermission([this.permissions.pricing_package.View]);
    let refundCancellationPolicyPermissions = this.permissionHandler.checkPermission([this.permissions.cancellation_policy.View]);
    let acceptedDocumentPermissions = this.permissionHandler.checkPermission([this.permissions.accepted_document.View]);
    let faqCategoryPermissions = this.permissionHandler.checkPermission([this.permissions.faq_category.View]);
    let faqPermissions = this.permissionHandler.checkPermission([this.permissions.faq.View]);
    let termsPermissions = this.permissionHandler.checkPermission([this.permissions.terms_conditons.View]);

    this.isAllPermit = permitBookingPermissions && facilitiesPermissions&&permitDashboardPermissions;
    this.isAllPermitSettingsPermission = permitTypePermissions && documentCategoryPermissions && checklistsPermissions && pricingPackagePermissions
      && refundCancellationPolicyPermissions && acceptedDocumentPermissions && faqCategoryPermissions && faqPermissions && termsPermissions;

    let isAnyPermitPermission = permitBookingPermissions || facilitiesPermissions;
    let isAnyPermitSettingsPermission = permitTypePermissions || documentCategoryPermissions || checklistsPermissions || pricingPackagePermissions
      || refundCancellationPolicyPermissions || acceptedDocumentPermissions || faqCategoryPermissions || faqPermissions || termsPermissions;
      
    this.isAnyPermitsPermission = isAnyPermitPermission || isAnyPermitSettingsPermission||permitDashboardPermissions;

    if (this.isAnyPermitsPermission) {
      if(this.isAllPermit || permitDashboardPermissions)
      {
        this.routerLinkPermit = "components/permit/dashboard";
      }
      else if (permitBookingPermissions) {
        this.routerLinkPermit = "components/book-permit";
      }
      else if (facilitiesPermissions) {
        this.routerLinkPermit = "components/permit/facilities";
      }
      else if (this.isAllPermitSettingsPermission || permitTypePermissions) {
        this.routerLinkPermit = "components/permit/permit-type";
      }
      else if (documentCategoryPermissions) {
        this.routerLinkPermit = "components/setting/document-category";
      }
      else if (checklistsPermissions) {
        this.routerLinkPermit = "components/setting/checklist";
      }
      else if (pricingPackagePermissions) {
        this.routerLinkPermit = "components/setting/pricing-package";
      }
      else if (refundCancellationPolicyPermissions) {
        this.routerLinkPermit = "components/setting/cancellation-policy";
      }
      else if (acceptedDocumentPermissions) {
        this.routerLinkPermit = "components/setting/accepted-document";
      }
      else if (faqCategoryPermissions) {
        this.routerLinkPermit = "components/setting/faq-category";
      }
      else if (faqPermissions) {
        this.routerLinkPermit = "components/setting/faq";
      }
      else if (termsPermissions) {
        this.routerLinkPermit = "components/setting/terms-conditions";
      }
    }
  }
  checkPermissionsViolationsRouter(): any {
    this.cd.detectChanges();
    //Dashboard
    let violationsDashboardPermissions = this.permissionHandler.checkPermission([this.permissions.dashboard_enforcement.View]);

    //violation Manage
    let violationManagePermissions = this.permissionHandler.checkPermission([this.permissions.violation_Management_List.View]);
    let violationAddPermissions = this.permissionHandler.checkPermission([this.permissions.violation_Management_add.View]);

    //violation Configuration
    let violationLocationPermissions = this.permissionHandler.checkPermission([this.permissions.Location_Configuration.View]);
    let violationDesignPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);
    let violationConnectionPermissions = this.permissionHandler.checkPermission([this.permissions.document_category.View]);
    
    this.isAllViolationsPermission = violationManagePermissions && violationAddPermissions;
    this.isAllConfigurationPermission = violationLocationPermissions && violationDesignPermissions && violationConnectionPermissions;

    let isAnyViolationsManagementPermission = violationManagePermissions || violationAddPermissions;;
    let isAnyConfigurationPermission = violationLocationPermissions || violationDesignPermissions || violationConnectionPermissions;;

    this.isAnyViolationsPermission = isAnyViolationsManagementPermission || isAnyConfigurationPermission||violationsDashboardPermissions;

    if (this.isAnyViolationsPermission) {
      if(this.isAllViolationsPermission || violationsDashboardPermissions)
      {
        this.routerLinkPermit = "components/permit/dashboard";
      }
      else if (violationManagePermissions) {
        this.routerLinkPermit = "components/book-permit";
      }
      else if (violationAddPermissions) {
        this.routerLinkPermit = "components/permit/facilities";
      }
      else if (this.isAllConfigurationPermission || violationLocationPermissions) {
        this.routerLinkPermit = "components/permit/permit-type";
      }
      else if (violationDesignPermissions) {
        this.routerLinkPermit = "components/setting/document-category";
      }
      else if (violationConnectionPermissions) {
        this.routerLinkPermit = "components/setting/checklist";
      }
    }
  }
  checkPermissionsSettingsRouter(): any {
    this.cd.detectChanges();
    //Location
    let zonePermissions = this.permissionHandler.checkPermission([this.permissions.zone.View]);
    let groupPermissions = this.permissionHandler.checkPermission([this.permissions.group.View]);
    let locationPermissions = this.permissionHandler.checkPermission([this.permissions.location.View]);

    //Users
    let userPermissions = this.permissionHandler.checkPermission([this.permissions.users.View])
    let rolesPermissions = this.permissionHandler.checkPermission([this.permissions.roles.View]);
    let classificationPermissions = this.permissionHandler.checkPermission([this.permissions.classification.View]);

    //Vehicles
    let vehicleColorsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_colors.View]);
    let vehicleMakesPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_makes.View]);
    let vehicleModelsPermissions = this.permissionHandler.checkPermission([this.permissions.vehicle_models.View]);
    let vehicleLicencePlatePermissions = this.permissionHandler.checkPermission([this.permissions.license_plate.View]);

    //Payments
    let PaymentPermissions = this.permissionHandler.checkPermission([this.permissions.Payment_gateways.View]);

    this.isAllLocationPermission = groupPermissions && zonePermissions && locationPermissions;
    this.isAllUsersPermission = userPermissions && rolesPermissions && classificationPermissions;
    this.isAllVehiclePermission = vehicleColorsPermissions && vehicleMakesPermissions && vehicleModelsPermissions && vehicleLicencePlatePermissions;
    this.isAllPaymentPermission = PaymentPermissions;

    let isAnyLocationPermission = groupPermissions || zonePermissions || locationPermissions;
    let isAnyUsersPermission = userPermissions || rolesPermissions || classificationPermissions;
    let isAnyVehiclePermission = vehicleColorsPermissions || vehicleMakesPermissions || vehicleModelsPermissions || vehicleLicencePlatePermissions;
    let isAnyPaymentPermission = PaymentPermissions;

    this.isAnySettingsPermission = isAnyLocationPermission || isAnyUsersPermission || isAnyVehiclePermission || isAnyPaymentPermission;

    if (this.isAnySettingsPermission) {
      if (this.isAllLocationPermission || zonePermissions) {
        this.routerLinkSettings = "components/setting/zone";
      }
      else if (groupPermissions) {
        this.routerLinkSettings = "components/setting/group";
      }
      else if (locationPermissions) {
        this.routerLinkSettings = "components/setting/location";
      }
      else if (this.isAllUsersPermission || rolesPermissions) {
        this.routerLinkSettings = "components/setting/roles";
      }
      else if (userPermissions) {
        this.routerLinkSettings = "components/setting/users";
      }
      else if (classificationPermissions) {
        this.routerLinkSettings = "components/setting/classification";
      }
      else if (this.isAllVehiclePermission || vehicleColorsPermissions) {
        this.routerLinkSettings = "components/setting/vehicle-colors";
      }
      else if (vehicleMakesPermissions) {
        this.routerLinkSettings = "components/setting/vehicle-makes";
      }
      else if (vehicleModelsPermissions) {
        this.routerLinkSettings = "components/setting/vehicle-models";
      }
      else if (vehicleLicencePlatePermissions) {
        this.routerLinkSettings = "components/setting/license-plate";
      }
      else if (PaymentPermissions || this.isAllPaymentPermission) {
        this.routerLinkSettings = "components/setting/payment";
      }
    }
  }  
}
