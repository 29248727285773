export const Permissions = { 
  group: {
    View: 'GeneralSetting.group.view',
    Add: 'GeneralSetting.group.Add',
    Edit: 'GeneralSetting.group.edit',
    Delete: 'GeneralSetting.group.delete',
  },
  zone: {
    View: 'GeneralSetting.zone.view',
    Add: 'GeneralSetting.zone.add',
    Edit: 'GeneralSetting.zone.edit',
    Delete: 'GeneralSetting.zone.delete',
  },
  location: {
    View: 'GeneralSetting.location.view',
    Add: 'GeneralSetting.location.add',
    Edit: 'GeneralSetting.location.edit',
    Delete: 'GeneralSetting.location.delete',
  },
  vehicle_makes: {
    View: 'GeneralSetting.vehicle-makes.view',
    Add: 'GeneralSetting.vehicle-makes.add',
    Edit: 'GeneralSetting.vehicle-makes.edit',
    Delete: 'GeneralSetting.vehicle-makes.delete',
  },
  vehicle_colors: {
    View: 'GeneralSetting.vehicle-colors.view',
    Add: 'GeneralSetting.vehicle-colors.add',
    Edit: 'GeneralSetting.vehicle-colors.edit',
    Delete: 'GeneralSetting.vehicle-colors.delete',
  },
  vehicle_models: {
    View: 'GeneralSetting.vehicle-models.view',
    Add: 'GeneralSetting.vehicle-models.add',
    Edit: 'GeneralSetting.vehicle-models.edit',
    Delete: 'GeneralSetting.vehicle-models.delete',
  },
  license_plate: {
    View: 'GeneralSetting.license-plate.view',
    Add: 'GeneralSetting.license-plate.add',
    Edit: 'GeneralSetting.license-plate.edit',
    Delete: 'GeneralSetting.license-plate.delete',
  },
  roles: {
    View: 'GeneralSetting.roles.view',
    Add: 'GeneralSetting.roles.add',
    Edit: 'GeneralSetting.roles.edit',
    Delete: 'GeneralSetting.roles.delete',
  },
  users: {
    View: 'GeneralSetting.users.view',
    Add: 'GeneralSetting.users.add',
    Edit: 'GeneralSetting.users.edit',
    Delete: 'GeneralSetting.users.delete',
  },
  Payment_gateways: {
    View: 'GeneralSetting.payment-gateway.view',
    Add: 'GeneralSetting.payment-gateway.add',
    Edit: 'GeneralSetting.payment-gateway.edit',
    Delete: 'GeneralSetting.payment-gateway.delete',
  },
  document_category: {
    View: 'Permit.document-category.view',
    Add: 'Permit.document-category.add',
    Edit: 'Permit.document-category.edit',
    Delete: 'Permit.document-category.delete',
  },
  classification :{
    View: 'GeneralSetting.classification.view',
    Add: 'GeneralSetting.classification.add',
    Edit: 'GeneralSetting.classification.edit',
    Delete: 'GeneralSetting.classification.delete',
  },
  checklist:{
    View: 'Permit.checklist.view',
    Add: 'Permit.checklist.add',
    Edit: 'Permit.checklist.edit',
    Delete: 'Permit.checklist.delete',
  },
  pricing_package :{
    View: 'Permit.pricing-package.view',
    Add: 'Permit.pricing-package.add',
    Edit: 'Permit.pricing-package.edit',
    Delete: 'Permit.pricing-package.delete',
  },
  permitType :{
    View: 'Permit.permit-Type.view',
    Add: 'Permit.permit-Type.add',
    Edit: 'Permit.permit-Type.edit',
    Delete: 'Permit.permit-Type.delete',
  },
  cancellation_policy : {
    View: 'Permit.cancellation-policy.view',
    Add: 'Permit.cancellation-policy.add',
    Edit: 'Permit.cancellation-policy.edit',
    Delete: 'Permit.cancellation-policy.delete',
  },
  accepted_document :{
    View: 'Permit.accepted-document.view',
    Add: 'Permit.accepted-document.add',
    Edit: 'Permit.accepted-document.edit',
    Delete: 'Permit.accepted-document.delete',
  },
  faq_category :{
    View: 'Permit.faq-category.view',
    Add: 'Permit.faq-category.add',
    Edit: 'Permit.faq-category.edit',
    Delete: 'Permit.faq-category.delete',
  }, 
  faq :{
    View: 'Permit.faq.view',
    Add: 'Permit.faq.add',
    Edit: 'Permit.faq.edit',
    Delete: 'Permit.faq.delete',
  },
  terms_conditons :{
    View: 'Permit.terms-conditions.view',
    Add: 'Permit.terms-conditions.add',
    Edit: 'Permit.terms-conditions.edit',
    Delete: 'Permit.terms-conditions.delete',
  }, 
  Facilities:{
    View: 'Permit.facilities.view',
    Add: 'Permit.facilities.add',
    Edit: 'Permit.facilities.edit',
    Delete: 'Permit.facilities.delete',
  },
  Violation_Configuration_Design:{
    View: 'Enforcement.violation-configuration-design.view',
    Add: 'Enforcement.violation-configuration-design.add',
    Edit: 'Enforcement.violation-configuration-design.edit',
    Delete: 'Enforcement.violation-configuration-design.delete',
  }, 
  Violation_Configuration_Numbering:{
    View: 'Enforcement.violation-configuration-numbering.view',
    Add: 'Enforcement.violation-configuration-numbering.add',
    Edit: 'Enforcement.violation-configuration-numbering.edit',
    Delete: 'Enforcement.violation-configuration-numbering.delete',
  },
  Location_Configuration :{
    View: 'Enforcement.location-configuration.view',
    Add: 'Enforcement.location-configuration.add',
    Edit: 'Enforcement.location-configuration.edit',
    Delete: 'Enforcement.location-configuration.delete',
  },
  book_permit:{
    View: 'Permit.book-permit.view',
    Add: 'Permit.book-permit.add',
    Edit: 'Permit.book-permit.edit',
    Delete: 'Permit.book-permit.delete',
  },
  quick_book:{
    View: 'Permit.quick-book.view',
    Add: 'Permit.quick-book.add',
    Edit: 'Permit.quick-book.edit',
    Delete: 'Permit.quick-book.delete',
  },
  dashboard_home:{
    View: 'Home.dashboard-home.view'
  },
  dashboard_permit:{
     View: 'Permit.dashboard-permit.view'
  },
  dashboard_enforcement:{
    View: 'Enforcement.dashboard-enforcement.view',
  },
  violation_Management_List:{
    View: 'Enforcement.violation-Management.view',
    Add: 'Enforcement.violation-Management.add',
    Edit: 'Enforcement.violation-Management.edit',
    Delete: 'Enforcement.violation-Management.delete',
  },
  violation_Management_add:{
    View: 'Enforcement.violation-Management-add.view',
    Add: 'Enforcement.violation-Management-add.add',
    Edit: 'Enforcement.violation-Management-add.edit',
    Delete: 'Enforcement.violation-Management-add.delete',
  }
};

