<header class="top-header mat-elevation-z5 d-block" [class.hidden]="toggleSearch"
  [ngClass]="{ test: getSideBarSate() }">
  <div class="d-flex justify-content-between" style="padding: 0 1.5rem">
    <div class="d-flex" style="height: 65px">
      <div class="gap-1 d-flex align-items-center">
        <button mat-icon-button (click)="toggleSidebar()">
          <mat-icon class="material-icons-outlined">menu</mat-icon>
        </button>
      </div>
      <div class="d-flex align-items-center" *ngIf="superadminbtn">
        <button mat-button>
          <a mat-list-item routerLink="super-admin/rokerplus">RokerPLUS Tenants
          </a>
        </button>
        <button mat-button>
          <a mat-list-item routerLink="super-admin/email-template">Manage Email Template
          </a>
        </button>
        <button mat-button>
          <a mat-list-item routerLink="super-admin/reports">Reports </a>
        </button>
      </div>
      <!-- new code -->
      <div>
       
        <mat-card-content>
          <div class="slider_headerresponsive">
            <ng-container *ngFor="let item of headerSubheaderItems">
              <button 
                mat-button 
                [ngClass]="{ 'active-header': isHeaderActive(item.header) }" 
                (click)="selectHeaderItem(item.header)" 
                *ngIf="hasPermission(item.permissions)">                
                <a 
                  mat-list-item 
                  [routerLink]="item.subheaders?.length ? null : getRouterLinkForSubheader(item)" 
                  routerLinkActive="active">
                  {{ item.header | translate }}
                </a>
              </button>
            </ng-container>
          </div>
        </mat-card-content>
      </div>      
       <!-- end new code -->
    </div>

    <!-- seting---submenu--start--here -->
    <div class="d-flex align-items-center gap-1">
      <button mat-icon-button (click)="openSearch()">
        <mat-icon class="material-icons-outlined">search</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="language" class="d-none d-sm-flex">
        <img src="{{ this.languageflg }}" alt="" />
      </button>
      <mat-menu #language class="rounded-4">
        <ng-container *ngFor="let lan of languageList">
          <button mat-menu-item (click)="selectLanguage(lan)">
            <div class="d-flex align-items-center gap-2">
              <img src="{{ lan.img }}" width="24" alt="" />
              <span>{{ lan.name }}</span>
            </div>
          </button>
        </ng-container>

      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="Notification">
        <mat-icon class="material-icons-outlined" matBadge="7" matBadgePosition="after"
          matBadgeColor="warn">notifications</mat-icon>
      </button>
      <button mat-icon-button (click)="paymentpage()">
        <svg clip-rule="evenodd" fill-rule="evenodd" height="512" stroke-linejoin="round" stroke-miterlimit="2"
          viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon">
            <path
              d="m21.722 21.57c.238.339.155.807-.184 1.044-.339.238-.807.155-1.044-.184l-1.619-2.312c-.238-.339-.155-.807.184-1.045.339-.237.807-.155 1.044.185z" />
            <path
              d="m22.137 17.291c.421.243.659.711.606 1.194-.053.482-.386.888-.849 1.034l-2.067.652-1.319 1.719c-.295.385-.791.559-1.262.444-.472-.116-.831-.499-.915-.977l-.998-5.67c-.083-.469.114-.943.503-1.216.39-.272.903-.295 1.315-.057z" />
            <path
              d="m1.25 6.75v-2.25c0-1.795 1.455-3.25 3.25-3.25h15c.862 0 1.689.342 2.298.952.61.609.952 1.436.952 2.298v2.25zm21.5 1.5v8.244c-.037-.024-.074-.047-.113-.069l-4.987-2.877c-.748-.432-1.679-.391-2.387.104-.708.496-1.065 1.357-.915 2.208l.998 5.67c.084.476.317.899.651 1.22h-11.497c-1.795 0-3.25-1.455-3.25-3.25v-11.25zm-17.5-4.75c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zm2.5 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zm2.5 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75z" />
          </g>
        </svg>
      </button>
      <mat-menu #Notification class="rounded-4 dropdown-notification">
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-red">shopping_cart</mat-icon>
          <span>New Sale from Gumroad</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-purple">email</mat-icon>
          <span>Check your new message</span>
        </button>

        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-green">task</mat-icon>
          <span>You have 5 new task</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined text-blue">supervisor_account</mat-icon>
          <span>10 New users registered</span>
        </button>
       
        <div class="mx-3 my-2">
          <button mat-raised-button color="primary" class="rounded-4 w-100">
            View All
          </button>
        </div>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="UserProfile">
        <mat-icon class="material-icons-outlined">account_circle</mat-icon>
      </button>
      <mat-menu #UserProfile class="rounded-4 dropdown-userprofile">
        <button mat-menu-item class="d-flex align-items-center justify-content-center text-center py-2">
          <div>
            <img src="assets/images/avatars/avatar-1.png" width="80" height="80" class="rounded-circle" alt="" />
          </div>
          <div>
            <h4 class="username mb-0">Hi, codervent!</h4>
            <p class="mb-0">coderventxyz.com</p>
          </div>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item>
          <mat-icon class="material-icons-outlined">account_circle</mat-icon>
          <span>Profile</span>
          <!-- mat-list-item
          routerLink="" -->
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logoutbtn()">
          <mat-icon class="material-icons-outlined">logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
  

  <!-- new code -->
  <div>
    <div class="submenu_block">
      <div class="submenu_list">
        <ul *ngIf="activeSubheaderItems?.length > 0">
          <ng-container  *ngFor="let subheaderItem of activeSubheaderItems">
          <li
           
            *ngIf="hasPermission(subheaderItem.permissions)"
             [routerLink]="subheaderItem.routerLink || getRouterLinkForSubheader(subheaderItem)"
          [ngClass]="{ 'active-class': isSubheaderActive(subheaderItem) }"
          routerLinkActive="active"
          (click)="onSubheaderClick(subheaderItem)"
          >
            <a mat-list-item >{{ subheaderItem.header }}</a>
          </li>
        </ng-container>
        </ul>
      </div>
    </div>
  </div>

</header>

<!--start search toolbar-->
<mat-toolbar class="searchbar mat-elevation-z5 justify-content-between" [ngClass]="{ 'full-search': getSideBarSate() }"
  [class.searchVisible]="toggleSearch">
  <button mat-icon-button class="btn-search">
    <mat-icon class="material-icons-outlined">search</mat-icon>
  </button>
  <input class="w-100 search-control" placeholder="Search" />
  <button mat-icon-button class="btn-search" (click)="searchClose()">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</mat-toolbar>
<!--end search toolbar-->