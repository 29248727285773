import { StorageService } from 'src/app/core/services/storage.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap, take, filter, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private localDateTime = this.getTimeZone();
  constructor(private http: HttpClient,
    private storage: StorageService,
    private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.url === 'assets/base-settings.json') {
    //   return next.handle(req);
    // }

    const clonedReq = this.addToken(req, this.storage.Token);

    return <any>next.handle(clonedReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
    }
    headerSettings['Content-Type'] = 'application/json; charset=utf-8';
    headerSettings['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
    headerSettings['Access-Control-Allow-Origin'] = '*';
  
    headerSettings['clientTimeZone'] = this.localDateTime;
    headerSettings['clientDateTime'] = this.formatDate(new Date());

    const newHeader = new HttpHeaders(headerSettings);

    const changedRequest = request.clone({
      url:  request.url,
      headers: newHeader
    });

    return changedRequest;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().pipe(
        switchMap((token: any) => {
          if (token) {
            this.storage.setToken(token.accessToken);
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(this.addToken(request, token.accessToken));
          }
          this.resetAndGoToLogin();
          return throwError('Error while refreshing token.');
        }),
        catchError((error) => {
          this.resetAndGoToLogin();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  refreshToken() {
    let refreshObject: any = {};
    // refreshObject.refreshToken = this.storage.RefreshToken;
    refreshObject.accessToken = this.storage.Token;
    // refreshObject.expiredAfter = null;
    return this.http.post<any>(environment.baseUrl + `login/Refresh`, refreshObject).pipe(
    // return this.http.post<any>(`login/Refresh`, refreshObject).pipe(
      tap((resp: any) => {
        if (resp) {
          localStorage.setItem('bearerToken', resp.accessToken);
          // localStorage.setItem('refreshToken', resp.refreshToken);
        }
      })
    );
  }

  resetAndGoToLogin() {
    this.storage.clear();
    this.router.navigate(['/auth/sign-in']);
  }

  public getTimeZone(): string {
    const datetimeString = new Date().toString(); 
    const timezone = this.getTimeZoneFromDate(datetimeString);
    return timezone;
  }

  getTimeZoneFromDate(dateString) {
    const regex = /\((?:\b\w+\s*)+\)$/;
    const match = regex.exec(dateString);
    if (match && match[1] == undefined) {
      return match[0];
    }
    return match ? match[1] : null;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    let dayORnight = "AM";
    if (date.getHours() > 11) { dayORnight = "PM"; }
    return (
      [
        date.getMonth() + 1,
        this.padTo2Digits(date.getDate()),
        date.getFullYear()
      ].join('/') +
      ' ' +
      [
        (date.getHours() % 12 || 12),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':') +
      ' ' +
      [
        dayORnight
      ]
    );
  }
}
