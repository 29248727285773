import { Messages } from './messages';

export class Constant {
  static readonly message = Messages;
  static readonly gridPageSize = 9;
  static readonly auditColumns = ['CreateDate', 'CreatedBy', 'ModifiedDate', 'ModifiedBy'];

  static readonly auditColumnsprime = [
    { field: 'createdDate', header: 'Created Date', type: 'date' },
    { field: 'createdName', header: 'Created By' },
    { field: 'modifiedDate', header: 'Modified Date', type: 'date' },
    { field: 'modifiedName', header: 'Modified By' }
  ]; 

  static readonly RightsType = {
    Add: 'isAdd',
    Edit: 'isEdit',
    Delete: 'isDelete',
    View: 'isView',
    Export: 'isExport',
    Sync: 'isSync'
  };

  static readonly DateValidateTypes = {
    CurrentDate: 1,
    CurrentPastDate: 2,
    FuturePastDate: 3,
    FutureDate: 4,
    OnlyPastDate: 5
  };

  static readonly DataType = {
    String: 1,
    Number: 2,
    Date: 3,
    Boolean: 4
  };

  static readonly PassWordMatchMsg = {
    passwordMatchMsg: "Passwords does not match"
  }
}
